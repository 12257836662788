import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { IConfig } from '../interfaces/config.interface';
import {
  IActivationRequest,
  IActivationResponse,
  IAuthUser,
  IBlacklistTokenResponse,
  ICompanyAccessList,
  ILoginRequest,
  ILoginResponse,
  ILogoutOptions,
  IResendActivationRequest,
  IResendActivationResponse,
  IResetPasswordEmail,
  IResetPasswordRequest,
  ISSOLoginRequest,
  ISSORegistrationRequest,
  ISignInSSORequest,
  ISignInSSOResponse,
  ISignUpRequest,
  ISignUpResponse
} from '../interfaces/users.interface';
import { APP_ROUTES } from '../routes';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUser: any;
  authUser: IAuthUser | null = null;
  stateItem: BehaviorSubject<IAuthUser | null> = new BehaviorSubject(this.authUser);
  stateItem$: Observable<IAuthUser | null> = this.stateItem.asObservable();
  config: IConfig;

  private readonly FLOW_KEY_STORAGE_KEY = 'flow_key';

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private userService: UserService
  ) {
    this.config = {
      apiBaseUrl: env.apiBaseUrl,
      contentType: env.contentType
    };
  }

  public activate(payload: IActivationRequest): Observable<IActivationResponse> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/account/activate/`, {
        ...payload
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public resendActivation(payload: IResendActivationRequest): Observable<IResendActivationResponse> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/account/re-send-activation/`, {
        ...payload
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public login(payload: ILoginRequest): Observable<ILoginResponse> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/token/`, {
        ...payload
      })
      .pipe(
        map((response: any) => {
          if (!response.status && response.status !== 'alert') {
            // TODO: no email or tokey_type provided confirm
            this.storeAuthUser({
              email: payload.email,
              isAuthenticated: true,
              accessToken: response.access,
              refreshToken: response.refresh,
              tokenType: response.token_type
            });
          }
          return response;
        }),
        tap(() => {
          this.userService.getCompanyAccess().subscribe({
            next: res => {
              this.userService.updateFullName(res.full_name);
            },
            error: (error: any) => {
              console.log('🚀 ~ AuthenticationService ~ this.userService.getCompanyAccess ~ error:', error);
              // Handle error if needed
            }
          });
        })
      );
  }

  public signInWithMicrosoft(): Observable<ISignInSSOResponse> {
    return this.signInWithSSO({ idp: 'microsoft', domain: window.location.origin });
  }

  public signInWithGoogle(): Observable<ISignInSSOResponse> {
    return this.signInWithSSO({ idp: 'google', domain: window.location.origin });
  }

  public signInWithSSO(payload: ISignInSSORequest): Observable<ISignInSSOResponse> {
    return this.httpClient
      .get(`${this.config.apiBaseUrl}/account/request-auth-url/`, {
        params: {
          ...payload
        }
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public ssoLogin(payload: ISSOLoginRequest): Observable<ILoginResponse> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/sso-login/`, {
        ...payload
      })
      .pipe(
        map((response: any) => {
          if (!response.status && response.status !== 'alert') {
            this.storeAuthUser({
              email: response.email,
              isAuthenticated: true,
              accessToken: response.access,
              refreshToken: response.refresh,
              tokenType: response.token_type
            });
          } else {
            this.storeAuthUser({
              email: response.solution.data.email,
              isAuthenticated: false,
              accessToken: response.access,
              refreshToken: response.refresh,
              tokenType: response.token_type
            });
          }
          return response;
        }),
        tap(() => {
          this.userService.getCompanyAccess().subscribe({
            error: (error: any) => {
              console.log('🚀 ~ AuthenticationService ~ this.userService.getCompanyAccess ~ error:', error);
              // Handle error if needed
            }
          });
        })
      );
  }

  public ssoRegister(payload: ISSORegistrationRequest): Observable<ILoginResponse> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/account/sso-registration/`, {
        ...payload
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public signUp(payload: ISignUpRequest): Observable<ISignUpResponse> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/account/register/`, {
        email: payload.email,
        password: payload.password,
        serial_key: payload.serialKey,
        accepted_terms: payload.termsCheckboxValue,
        accepted_services: payload.termsServiceCheckboxValue
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public isAuthenticated(): boolean {
    const authUser = this.getAuthUser();
    if (authUser) {
      return this.checkAuth(authUser);
    }

    return false;
  }

  public logout(request: ILogoutOptions = { navigate: true }): Observable<IBlacklistTokenResponse> {
    this.backlistToken()
      .subscribe({
        next: (result: IBlacklistTokenResponse) => {
          return result;
        },
        error: (error: any) => {
          console.error('error', error);
          return error;
        }
      })
      .add(() => {
        this.removeState();
        this.userService.removeState();
        if (request.navigate) {
          this.router.navigateByUrl(APP_ROUTES.LOGIN.PATH);
        }
      });
    return new Observable<IBlacklistTokenResponse>();
  }

  public sendResetPassword(payload: IResetPasswordRequest): Observable<any> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/account/reset-password/`, {
        uidb64: payload.uidb64,
        token: payload.token,
        new_password: payload.new_password
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public sendResetEmail(payload: IResetPasswordEmail): Observable<any> {
    return this.httpClient
      .post(`${this.config.apiBaseUrl}/account/forgot-password/`, {
        email: payload.email
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public backlistToken(): Observable<IBlacklistTokenResponse> {
    const authUser = this.getAuthUser();
    if (authUser) {
      return this.httpClient
        .post(`${this.config.apiBaseUrl}/token/blacklist/`, {
          refresh: authUser.accessToken
        })
        .pipe(
          map((response: any) => {
            return response;
          })
        );
    }
    return new Observable<IBlacklistTokenResponse>();
  }

  public getAuthUser(): IAuthUser | null {
    const authUser = localStorage.getItem('authUser');
    if (authUser) {
      const user = JSON.parse(authUser) as IAuthUser;
      return user;
    }
    return null;
  }

  public getAuthUserRole(): string | null {
    const authUser = localStorage.getItem('companyAccessList');

    if (authUser) {
      const user = JSON.parse(authUser) as ICompanyAccessList;
      if (user.companies && user.companies.length > 0) {
        const { role } = user.activeCompany;

        return role;
      }
    }
    return null;
  }

  public refreshToken(): Observable<ILoginResponse> {
    const authUser = this.getAuthUser();
    if (authUser) {
      return this.httpClient
        .post(`${this.config.apiBaseUrl}/token/refresh/`, {
          refresh: authUser.refreshToken
        })
        .pipe(
          map((response: any) => {
            this.storeAuthUser({
              email: authUser.email,
              isAuthenticated: true,
              accessToken: response.access,
              refreshToken: response.refresh,
              tokenType: response.token_type
            });
            return response;
          })
        );
    }
    return new Observable<ILoginResponse>();
  }

  checkAuth(auth: IAuthUser): boolean {
    return auth.isAuthenticated;
  }

  setState(item: IAuthUser) {
    this.stateItem.next(item);
  }

  removeState() {
    localStorage.removeItem('authUser');
    this.stateItem.next(null);
  }

  storeAuthUser(authUser: IAuthUser) {
    this.setState(authUser);
    localStorage.setItem('authUser', JSON.stringify(authUser));
  }

  setFlowKey(flowKey: string): void {
    localStorage.setItem(this.FLOW_KEY_STORAGE_KEY, flowKey);
  }

  getFlowKey(): string | null {
    return localStorage.getItem(this.FLOW_KEY_STORAGE_KEY);
  }

  removeFlowKey(): void {
    localStorage.removeItem(this.FLOW_KEY_STORAGE_KEY);
  }

  public changePassword(payload: IChangePasswordReq): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authUser?.accessToken}`
      })
    };

    return this.httpClient
      .patch(
        `${this.config.apiBaseUrl}/account/change-password/`,
        {
          ...payload
        },
        httpOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}

export interface IChangePasswordReq {
  old_password: string;
  new_password: string;
}
